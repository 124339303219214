<template>
    <div class="allocatePop">
        <div class="allcocate-con">
            <div class="types">
                <span>负责运输：</span>
                <el-radio-group v-model="supplyType" @change="changeSupplyType">
                    <el-radio
                        label="供应商"
                    ></el-radio>
                    <el-radio
                        label="运输单位"
                    ></el-radio>
                </el-radio-group>
            </div>
            <div class="main">
                <div class="type1" v-if="supplyType==='供应商'">
                    <div class="text-center text">
                        {{ saveData.transports[0].transport_name }}
                    </div>
                    <div class="text-center">
                        <el-input
                            placeholder="请输入分配量"
                            type="number"
                            v-model="saveData.transports[0].supply_count"
                            @mousewheel.native.prevent
                        ></el-input>
                    </div>
                    <div class="text-center">
                        <el-input
                            placeholder="请输入暗扣量"
                            type="number"
                            v-model="saveData.transports[0].covert_deduction_weight"
                            @mousewheel.native.prevent
                        ></el-input>
                    </div>
                    <template v-if="rawMaterialOrder.is_restrict_supply">
                        <div class="text-center">
                            是否限制
                            <el-switch
                                v-model="saveData.transports[0].is_restrict_supply"
                                :active-value="1"
                                :inactive-value="0"
                            ></el-switch>
                        </div>
                        <div class="text-center">
                            <el-input
                                placeholder="限制进场重量"
                                type="number"
                                v-model="saveData.transports[0].restrict_supply_count"
                                @mousewheel.native.prevent
                            ></el-input>
                        </div>
                    </template>
                </div>
                <div class="type2" v-else>
                    <div class="rows">
                        <div
                            class="row"
                            v-for="(item, index) in saveData.transports"
                            :key="index"
                        >
                            <el-input
                                :class="['input1',rawMaterialOrder.is_restrict_supply?'small-width':'']"
                                placeholder=""
                                @focus="choseSupply(index)"
                                v-model="item.transport_name"
                            >
                                <i slot="suffix" class="iconfont icon009"></i>
                            </el-input>
                            <el-input
                                class="input2"
                                type="number"
                                placeholder="分配量"
                                @mousewheel.native.prevent
                                v-model="item.supply_count"
                            ></el-input>
                            <el-input
                                class="input2"
                                type="number"
                                placeholder="暗扣量"
                                @mousewheel.native.prevent
                                v-model="item.covert_deduction_weight"
                            ></el-input>
                            <template v-if="rawMaterialOrder.is_restrict_supply">
                                是否限制
                                <el-switch
                                    v-model="item.is_restrict_supply"
                                    :active-value="1"
                                    :inactive-value="0"
                                ></el-switch>
                                <el-input
                                    class="input2"
                                    type="number"
                                    placeholder="限制进场重量"
                                    @mousewheel.native.prevent
                                    v-model="item.restrict_supply_count"
                                ></el-input>
                            </template>
                            <span
                                class="iconfont iconjianqu-fangxing-mianxing"
                                @click="delSupplyRow(index)"
                            ></span>
                        </div>
                    </div>
                    <div class="new-row" @click="addSupplyRow">
                        <span class="iconfont icontianjia1"></span>
                        <span>点击此处新增</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="btn-con">
            <el-button
                type="primary"
                size="medium"
                class="sure-btn"
                @click="batchSave()"
            >
                确定
            </el-button>
            <el-button
                type="primary"
                size="medium"
                @click="closeAllocate"
                plain
            >
                取消
            </el-button>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        extr: {
            type: Object,
        },
    },
    data() {
        return {
            // 原材料订单id
            checked: '',
            // 选中供应商
            checkedSupplier: {},
            // 原材料订单数据
            rawMaterialOrder: {},
            // 场站id
            stationId: '',
            supplierIndex: 0,
            // 供应类型
            supplyType: '供应商',
            // 供应商列表
            saveData: {
                catalog_supply_id: '',
                ciid: '',
                cmvid: '',
                contract_no: '',
                transport_contract_id: '',
                transport_contract_no: '',
                transports: [
                    {
                        covert_deduction_weight: null,
                        supply_count: null,
                        transport_id: '',
                        transport_name: '',
                        transport_type: 0,
                        transport_contract_id: '',
                        transport_contract_no: '',
                    },
                ],
            },
            // 激活供应商index
            transportsIndex: 0,
        };
    },
    watch: {},
    computed: {},
    created() {},
    mounted() {
        // 原材料id
        this.checked = this.extr.checkedMaterial;
        // 供应商数据
        this.checkedSupplier = this.extr.checkedSupplier;
        // 原材料订单数据
        this.rawMaterialOrder = this.extr.rawMaterialOrder;
        // 场站id
        this.stationId = this.extr.stationId;
        this.allocatePop();
    },
    methods: {
        // 关闭弹框
        closeAllocate() {
            this.$parent.hide();
        },
        // 新增运输单位
        addSupplyRow() {
            const json = {
                covert_deduction_weight: null,
                supply_count: null,
                transport_id: '',
                transport_name: '',
                transport_type: 1,
            };
            this.saveData.transports.push(json);
        },
        // 删除运输单位
        delSupplyRow(index) {
            this.saveData.transports.splice(index, 1);
        },
        // 选择运输
        choseSupply(index) {
            this.transportsIndex = index;
            let transport_ids = '';
            this.saveData.transports.map(item => {
                if (item.transport_id) {
                    transport_ids += item.transport_id + ',';
                }
            });
            if (transport_ids) {
                transport_ids = transport_ids.substr(0, transport_ids.length - 1);
            }
            this.$toast({
                title: true,
                text: '选择运输单位',
                type: 'eject',
                width: '12rem',
                height: '80%',
                t_url: 'tableList/index',
                viewPosition: 'view',
                extr: {
                    code: {
                        TableCode: 'cg_mrjhpf_xzysdw',
                        QueryString: 'transport_ids=' + transport_ids
                                + '&supplier_id=' + this.checkedSupplier.supplier_id
                                + '&type_dic_code=' + this.rawMaterialOrder.type_dic_code
                                + '&child_type_code=' + this.rawMaterialOrder.goods_code
                                + '&spec_code=' + this.rawMaterialOrder.spec_code
                                + '&station_id=' + this.stationId
                                + '&source_id=' + this.checkedSupplier.source_id,
                    },
                    closeBtn: {},
                    sureCallBack: this.selectedSupply,
                    tabledataParams: {
                        transport_ids: transport_ids,
                    },
                },
            });
        },
        // 运输单位选择完成
        selectedSupply(data) {
            if (data.length === 1) {
                const json = {
                    covert_deduction_weight: null,
                    supply_count: null,
                    transport_id: data[0].id,
                    transport_name: data[0].name,
                    transport_type: 1,
                    transport_contract_id: data[0].transport_contract_id,
                    transport_contract_no: data[0].transport_contract_no,
                };
                this.saveData.transports.splice(this.transportsIndex, 1, json);
            } else {
                this.saveData.transports.splice(this.transportsIndex, 1);
                data.map(item => {
                    const json = {
                        covert_deduction_weight: null,
                        supply_count: null,
                        transport_id: item.id,
                        transport_name: item.name,
                        transport_type: 1,
                        transport_contract_id: item.transport_contract_id,
                        transport_contract_no: item.transport_contract_no,
                    };
                    this.saveData.transports.push(json);
                });
            }
        },
        // 保存分配供应信息
        async batchSave() {
            const arr = this.saveData.transports.filter(item => item.supply_count === null);
            if (arr.length > 0) {
                this.$message.warning('请输入分配量！');
                return;
            }
            const res = await this.batchSaveApi();
            if (res) {
                this.$message.success('分配成功！');
                this.$parent.hide();
                this.extr.getProcurementDailyPlanSupplierSupply();
                this.extr.getProcurementDailyPlanSupplierNosupply();
            }
        },
        // 更换方式
        changeSupplyType(value) {
            if (value === '供应商') {
                this.saveData.transports = [
                    {
                        covert_deduction_weight: null,
                        supply_count: null,
                        transport_id: this.checkedSupplier.supplier_id,
                        transport_name: this.checkedSupplier.supplier_name,
                        transport_type: 0,
                        transport_contract_id: this.checkedSupplier.transport_contract_id,
                        transport_contract_no: this.checkedSupplier.transport_contract_no,
                    },
                ];
            } else {
                this.saveData.transports = [
                    {
                        covert_deduction_weight: null,
                        supply_count: null,
                        transport_id: '',
                        transport_name: '',
                        transport_type: 1,
                        transport_contract_id: '',
                        transport_contract_no: '',
                    },
                ];
            }
        },
        // 分配拉取数据
        async allocatePop() {
            this.saveData = {
                catalog_supply_id: this.checkedSupplier.id,
                ciid: this.checkedSupplier.ciid,
                cmvid: this.checkedSupplier.cmvid,
                contract_no: this.checkedSupplier.contract_no,
                transports: [
                    {
                        covert_deduction_weight: null,
                        supply_count: null,
                        transport_id: '',
                        transport_name: '',
                        transport_type: 0,
                        transport_contract_id: '',
                        transport_contract_no: '',
                        is_restrict_supply: 0,
                        restrict_supply_count: 0,
                    },
                ],
            };
            const res = await this.getSupply();
            if (res) {
                if (res.length > 0) {
                    if (res[0].transport_type === 0) {
                        this.supplyType = '供应商';
                    } else {
                        this.supplyType = '运输单位';
                    }
                    this.saveData.transports = res;
                } else {
                    this.supplyType = '供应商';
                    this.saveData.transports[0].transport_type = 0;
                    this.saveData.transports[0].transport_id = this.checkedSupplier.supplier_id;
                    this.saveData.transports[0].transport_name = this.checkedSupplier.supplier_name;
                    this.saveData.transports[0].transport_contract_id = this.checkedSupplier.transport_contract_id;
                    this.saveData.transports[0].transport_contract_no = this.checkedSupplier.transport_contract_no;
                    this.saveData.transports[0].is_restrict_supply = this.checkedSupplier.is_restrict_supply;
                    this.saveData.transports[0].restrict_supply_count = this.checkedSupplier.restrict_supply_count;
                }
            }
        },
        // 拉取分配数据
        getSupply() {
            const path = '/interfaceApi/procurement/daily/plan/supply/get/' + this.checked + '?supplier_id=' + this.checkedSupplier.supplier_id;
            return this.$axios.$get(path, { defEx: true });
        },
        // 保存分配供应信息
        batchSaveApi() {
            const path = '/interfaceApi/procurement/daily/plan/supply/batchsave/' + this.checked;
            return this.$axios.$post(path, this.saveData, { defEx: true });
        },
    },
};
</script>
<style lang="stylus">
    .allocatePop
        padding .2rem
        height 100%
        font-size .16rem
        .el-radio
            .el-radio__label
                font-size .16rem
        .btn-con
            text-align center
            margin: 0
            .sure-btn
                margin-right .2rem
        .allcocate-con
            height calc(100% - .6rem)
            .types
                margin-bottom .2rem
                text-align center
            .main
                height calc(100% - .6rem)
                margin .1rem 0
                .type1
                    height 100%
                    .el-input__inner
                        width 60%
                    .text
                        padding .2rem 0 .1rem 0
                    input::-webkit-outer-spin-button
                              -webkit-appearance none !important
                              margin 0
                    input::-webkit-inner-spin-button
                              -webkit-appearance none !important
                              margin 0
                .type2
                    height 100%
                    .rows
                        height calc(100% - .5rem)
                        overflow-y auto
                        .row
                            margin-bottom .1rem
                            .input1
                                width calc(100% - 2.98rem)
                                line-height .32rem
                                margin-right .1rem
                            .small-width
                                width calc(100% - 5.5rem)
                            .input2
                                width 1.2rem
                                margin-right .1rem
                            .el-switch
                                margin-right .1rem
                            .el-input__inner
                                padding 0 .08rem
                                &::-webkit-input-placeholder
                                    font-size .14rem
                                &::-moz-placeholder
                                    font-size .14rem
                                &::-ms-input-placeholder
                                    font-size .14rem
                            input::-webkit-outer-spin-button
                                      -webkit-appearance none !important
                                      margin 0
                            input::-webkit-inner-spin-button
                                      -webkit-appearance none !important
                                      margin 0
                            .iconfont
                                font-size .18rem
                                color #D5000F
                            .icon009
                                color #979EA7
                    .new-row
                        height .42rem
                        border 1px dashed #D7D7D7
                        background #FCFCFC
                        text-align center
                        line-height .42rem
                        cursor pointer
                        .iconfont
                            margin-right .05rem
        .text-center
            text-align center
            margin 10px 0
</style>
